.labels {
  font-family: Gilroy-Medium;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  letter-spacing: 0;
  color: #444444;
  display: flex;
  align-items: center;
}

.textStyleSelect {
  width: 100%;
  height: 40px;
  border-radius: 4px;
  padding-left: 20px;
  font-family: Gilroy-Regular;
  font-size: 16px;
  font-weight: normal;
  letter-spacing: 0;
  color: #000000;
  border: 1px solid #c7c7c7;
}

.textStyleSelectWhite {
  background-color: white;
}


.hr {
  flex-grow: 1;
  border-width: 0.5;
  text-align: "center";
  margin-left: "5px";
}
