textarea:focus {
  outline: 1px solid white;
}
.textarea {
  width: 100%;
  /* min-height: 220px; */
  border-radius: 4px;
  background-color: white;
  font-size: 16px;
  font-family: Gilroy-Regular;
  letter-spacing: 0;
  padding-left: 20px;
  padding-top: 10px;
  color: #444444;
  border: 1px solid #ffffff;
  box-shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0px;
}
.flex {
  display: flex;
  align-items: center;
}
.flexMobile {
  font-family: Gilroy-Medium;
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  line-height: 17px;
  letter-spacing: 0;
  color: #444444;
  display: flex;
  align-items: center;
}
.hr {
  flex-grow: 1;
  border-width: 0.5;
  text-align: center;
  margin-left: 5px;
}
