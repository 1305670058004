.title {
  display: flex;
  justify-content: center;
  width: -webkit - fill - available;
}
.filterWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: baseline;
  margin-top: 50px;
}

.datePickerPosition {
  display: flex;
  align-self: center;
}
.formFilter {
  height: 400px;
  display: flex;
  justify-content: space-around;
}
