.modalTitleWithLogo {
  display: flex;
  align-items: start;
  gap: 10px;
}

.bodyWrapper {
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 30px;
    margin-right: 30px;
}

.sectionTitle {
    font-family: Gilroy-Bold;
    font-size: 20px;
    padding-bottom: 10px;
}

.sectionTitle--paddingTop {
    padding-top: 20px;
}

.text {
    font-family: Gilroy-Regular;
}

.uploadedFileTitle {
    font-family: Gilroy-Bold;
    margin-top: 5px;
    margin-bottom: 5px;
}

.linkedText {
    font-family: Gilroy-Medium;
    color: #005a8c;
    margin-top: 5px;
    margin-bottom: 5px;
    cursor: pointer;
}

.textOnProhibitionSign {
    font-family: Gilroy-Regular;
    overflow-wrap: break-word;
    padding-bottom: 10px;
}

.usersNames {
    font-family: Gilroy-Regular;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3; /* number of lines to show */
    -webkit-box-orient: vertical;
    cursor: default;
}

.twoColumnsContainer {
    display: flex;
    flex-direction: row;
}

.leftColumnContainer {
    flex: 1.1;
}

.rightColumnContainer {
    flex: 0.9;
}

.uploadedFileName {
    font-family: Gilroy-Regular;
    font-size: 14px;
    color: #005a8c;
    cursor: pointer;
}

.editButton {
    padding-top: 10px;
    display: flex;
    justify-content: center;
}
  