.labels {
  font-family: Gilroy-Medium;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  letter-spacing: 0;
  color: #444444;
  display: flex;
  align-items: center;
}

.hr {
  flex-grow: 1;
  border-width: 0.5;
  text-align: "center";
  margin-left: "5px";
}

.textStyleInput {
  width: 100%;
  height: 60px;
  border-radius: 4px;
  background-color: white;
  padding-left: 20px;
  font-family: Gilroy-Regular;
  font-size: 16px;
  font-weight: normal;
  letter-spacing: 0;
  color: #444444;
  border: 1px solid #f2f2f2;
}

.textStyleInputDisable {
  width: 100%;
  height: 60px;
  border-radius: 4px;
  background-color: white;
  padding-left: 20px;
  font-family: Gilroy-Regular;
  font-size: 16px;
  font-weight: normal;
  letter-spacing: 0;
  color: #444444;
  border: 1px solid #f2f2f2;
  background-color: rgb(199, 199, 199);
}
