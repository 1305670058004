.toolbar {
  display: flex;
  align-items: baseline;
  margin-bottom: 35px;
}

.toolbar__titleAndInfo {
  display: flex;
  flex: 2;
  align-items: baseline;
}

.toolbar__titleAndInfo__title {
  font-family: Gilroy-Bold;
  font-size: 24px;
  font-weight: bold;
  font-style: normal;
  margin-right: 60px;
}

.pzInfo {
  display: flex;
  flex-direction: row;
  margin-right: 60px;
}

.pzInfo__label {
  font-family: Gilroy-Regular;
  font-size: 16px;
  display: flex;
  flex-direction: row;
  margin-right: 8px;
}

.pzInfo__value {
  font-family: Gilroy-Bold;
  font-size: 16px;
}

.addVehicle {
  color: #005a8c;
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
  flex: 1;
}
