.textStyleInputEditModal {
  width: 100%;
  height: 40px;
  border-radius: 4px;
  background-color: white;
  padding-left: 20px;
  font-family: Gilroy-Regular;
  font-size: 16px;
  font-weight: normal;
  letter-spacing: 0;
  color: #444444;
  border: 1px solid #c7c7c7;
}
.buttonContainer {
  /* margin-top: 75px; */
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
