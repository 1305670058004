.section {
    flex: 1;
    flex-direction: column;
    max-width: 800px;
    margin-top: 50px;
    margin-right: 30px;
    margin-left: 30px;
}

.section__title {
    padding-bottom: 5px;
    font-family: Gilroy-Bold, serif;
    font-size: 20px;
    color: #000000;
    grid-column: span 7;
}

.addressContainer {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-column-gap: 40px;
}

.rowContainer {
}
