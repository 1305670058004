.modal__title {
  margin-left: 25px;
  display: flex;
  align-items: start;
  gap: 10px;
}

.formContainer {
  display: flex;
  flex-direction: column;
}

.container {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.container--marginRight {
  margin-right: 20px;
}

.container--marginLeft {
  margin-left: 20px;
}

.container--margineRight {
  margin-right: 35px;
}

.textarea {
  width: 100%;
  /* min-height: 220px; */
  border-radius: 4px;
  background-color: white;
  font-size: 16px;
  font-family: Gilroy-Regular;
  letter-spacing: 0;
  padding-left: 20px;
  padding-top: 10px;
  color: #444444;
  border: 1px solid #ffffff;
  box-shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0px;
}

.container--margineRightSmall {
  margin-right: 20px;
}

.section {
  flex: 1;
  flex-direction: column;
  max-width: 800px;
  margin-top: 50px;
  margin-right: 30px;
  margin-left: 30px;
}

.section__title {
  padding-bottom: 5px;
  font-family: Gilroy-Bold;
  font-size: 20px;
  color: #000000;
}

.hr {
  flex-grow: 1;
  border-width: 0.5px;
  text-align: center;
  margin-left: 5px;
}

.pzNumberNameContainer {
  display: flex;
  flex-direction: row;
  margin-bottom: 15px;
}

.pzNumberContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.pzNameContainer {
  display: flex;
  flex-direction: column;
  flex: 2;
  margin-left: 40px;
}

.rowContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 15px;
}

.nameContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex: 2;
  margin-left: 40px;
}

.flexRowContainer {
  display: flex;
  flex-direction: row;
  margin-bottom: 15px;
}

.fileUpload {
  font-family: Gilroy-Medium;
  font-size: 14px;
  color: #005a92;
  cursor: pointer;
}

.buttonContainer {
  margin-top: 35px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.textStyleSelectEditModal {
  width: 100%;
  height: 40px;
  border-radius: 4px;
  padding-left: 20px;
  font-family: Gilroy-Regular;
  font-size: 16px;
  font-weight: normal;
  letter-spacing: 0;
  background-color: #c7c7c7;
  color: #000000;
  border: 1px solid #f2f2f2;
}

.textStyleInputEditModal {
  width: 100%;
  height: 40px;
  border-radius: 4px;
  background-color: white;
  padding-left: 20px;
  font-family: Gilroy-Regular;
  font-size: 16px;
  font-weight: normal;
  letter-spacing: 0;
  color: #444444;
  border: 1px solid #c7c7c7;
}

.textStyleInputEditModalDisabled {
  width: 100%;
  height: 40px;
  border-radius: 4px;
  background-color: rgb(199, 199, 199);
  padding-left: 20px;
  font-family: Gilroy-Regular;
  font-size: 16px;
  font-weight: normal;
  letter-spacing: 0;
  color: #444444;
  border: 1px solid #c7c7c7;
}

.textArea {
  width: 100%;
  minHeight: 60px;
  border-radius: 4px;
  background-color: white;
  padding-left: 20px;
  font-family: Gilroy-Regular;
  font-size: 16px;
  font-weight: normal;
  letter-spacing: 0;
  color: #444444;
  border: 1px solid #c7c7c7;
}

.addManagerButton {
  border: none;
  background: none;
  color: #005a8c;
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  flex: 1;
}

.addManagerButtonDisabled {
  border: none;
  background: none;
  color: #c7c7c7;
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  flex: 1;
}

.managerListContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 15px;
}

.selectManager {
  width: 100%;
}

.removeManager {
  margin-right: -25px;
  cursor: pointer;
}

.text {
  font-family: Gilroy-Regular;
}

.documentRow {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}

.uploadedFileName {
  font-family: Gilroy-Regular;
  font-size: 14px;
}

.documentLine {
  align-self: center;
  border-bottom: solid;
  border-bottom-width: 2px;
  border-color: #f0f0f0;
  flex-grow: 3;
  margin-left: 15px;
  margin-right: 15px;
}

.removeDocument {
  justify-self: flex-end;
  cursor: pointer;
}

.paddingTop {
  padding-top: 20px;
}

.noArrowSelect {
  appearance: none;
}
.internationalFeeContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex: 2;
  margin-left: 40px;
}
.textStyleI {
  width: 100%;
  height: 40px;
  border-radius: 4px;
  background-color: white;
  padding-left: 20px;
  font-family: Gilroy-Regular;
  font-size: 16px;
  font-weight: normal;
  letter-spacing: 0;
  color: #444444;
  border: 1px solid #c7c7c7;
}