.flex {
  display: flex;
  flex-direction: column;
}

.italicLabelsDesktop {
  font-family: Gilroy-Medium;
  font-size: 14px;
  font-weight: 500;
  color: #444444;
  margin-right: 5px;
}

.boldDesktopValues {
  font-family: Gilroy-Bold;
  font-size: 18px;
  font-style: normal;
  color: #444444;
}

.container {
  text-align: center;
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 20px;
}
