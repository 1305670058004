.modal__title {
  margin-left: 25px;
}

.formContainer {
  display: flex;
  flex-direction: column;
}

.section {
  flex: 1;
  flex-direction: column;
  max-width: 800px;
  margin-top: 50px;
  margin-right: 30px;
  margin-left: 30px;
}

.section__title {
  padding-bottom: 5px;
  font-family: Gilroy-Bold;
  font-size: 20px;
  color: #000000;
}

.editor {
  margin-top: 15px;
}

.section--payment {
  margin-top: 30px;
}

.section__title--admin {
  padding-top: 10px;
  padding-bottom: 10px;
}

.hr {
  flex-grow: 1;
  border-width: 0.5px;
  text-align: center;
  margin-left: 5px;
}
.container {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.container--margineRight {
  margin-right: 40px;
}
.container--margineRightSmall {
  margin-right: 20px;
}
.container--margineLeft {
  margin-left: 20px;
}

.fileUpload {
  font-family: Gilroy-Medium;
  font-size: 14px;
  color: #005a92;
  cursor: pointer;
}

.buttonContainer {
  margin-top: 35px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}



.addAddressButton {
  border: none;
  background: none;
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  flex: 1;
  margin-top: 10px;
  margin-bottom: 10px;
}

.addAddressButton--enabled {
  color: #005a8c;
}

.addAddressButton--disabled {
  color: #c7c7c7;
}

.text {
  font-family: Gilroy-Regular;
}

.uploadedFile {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}

.uploadedFile__name {
  font-family: Gilroy-Regular;
  font-size: 14px;
}

.uploadedFile__removeBtn {
  justify-self: flex-end;
  cursor: pointer;
}

.uploadedFile__line {
  align-self: center;
  border-bottom: solid;
  border-bottom-width: 2px;
  border-color: #f0f0f0;
  flex-grow: 3;
  margin-left: 15px;
  margin-right: 15px;
}

.textStyleInputEditModal {
  width: 100%;
  height: 40px;
  border-radius: 4px;
  background-color: white;
  padding-left: 20px;
  font-family: Gilroy-Regular;
  font-size: 16px;
  font-weight: normal;
  letter-spacing: 0;
  color: #444444;
  border: 1px solid #c7c7c7;
}

.clientSectionContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 10px;
}

.paymentSection__rowContainer {
  display: flex;
  flex-direction: row;
}


.clientLeftInput {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.clientRightInput {
  flex: 1;
  margin-left: 40px;
}

.addComboBoxButton {
  border: none;
  background: none;
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  flex: 1;
}

.addComboBoxButton--enabled {
  color: #005a8c;
}
.addComboBoxButton--disabled {
  color: #c7c7c7;
}

.combo {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.combo__select {
  width: 100%;
}

.combo__removeBtn {
  margin-right: -25px;
  margin-bottom: 15px;
  cursor: pointer;
}

