.toolbar {
  font-family: Gilroy-Bold;
  font-size: 20px;
  font-weight: bold;
  font-style: normal;
  text-align: left;
  letter-spacing: 0;
  color: #000000;
  margin-bottom: 15px;
}

.header {
  display: flex;
  text-align: start;
  justify-content: space-between;
  margin-bottom: 5px;
}

.header__title {
  font-family: Gilroy-Bold;
  font-size: 20px;
  font-weight: bold;
  color: #000000;
}

.header__addButton {
  border: none;
  background: white;
}

.header__addButton__text {
  text-align: center;
  vertical-align: middle;
  color: #005a8c;
}

.label__text {
    margin-top: 10px;
    margin-right: 10px;
    font-family: Gilroy-Bold;
    font-size: 16px;
    font-style: normal;
    letter-spacing: 0;
    color: #000000;
}

.countrySelect {
    height: 40px;
    margin-right: 5px;
}
