.toolbar {
  background-color: white;
  color: black;
  display: flex;
  justify-content: space-between;
}

.support {
  position: absolute;
  bottom: 6%;
  left: 8%;
  font-size: 16px;
}

.version {
  position: absolute;
  bottom: 2%;
  left: 8%;
  font-size: 14px;
}
