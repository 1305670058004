.entityDetailContainer {
  display: flex;
  justify-content: space-between;
  padding-bottom: 15px;
}

.entityDetailLabel {
  font-family: Gilroy-Regular;
}

.entityDetailLine {
  align-self: center;
  border-bottom: solid;
  border-bottom-width: 2px;
  border-color: #f0f0f0;
  flex-grow: 3;
  margin-left: 15px;
  margin-right: 15px;
}

.entityDetailValue {
  font-family: Gilroy-Bold;
  flex-wrap: nowrap;
}
