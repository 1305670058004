.flex {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.italicLabelsDesktop {
  font-family: Gilroy-Medium;
  font-size: 14px;
  font-weight: 500;
  color: #444444;
  margin-right: 5px;
  margin-top: 20px;
}
.boldDesktopValues {
  font-family: Gilroy-Bold;
  font-size: 18px;
  font-style: normal;
  color: #444444;
}
.wrapper {
  justify-content: center;
}
.success {
  font-family: Gilroy-Medium;
  font-size: 14px;
  font-weight: 500;
  color: green;
  margin-right: 5px;
  margin-top: 20px;
}
.fail {
  font-family: Gilroy-Medium;
  font-size: 14px;
  font-weight: 500;
  color: red;
  margin-right: 5px;
  margin-top: 20px;
}
.labelsDesktop {
  font-family: Gilroy-Medium;
  font-size: 14px;
  font-weight: 500;
  color: #444444;
  margin-right: 5px;
  margin-top: 20px;
}
