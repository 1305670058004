.entityDetail {
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
}

.entityDetail__label {
  font-family: Gilroy-Medium;
  font-size: 16px;
}

.entityDetail__line {
  align-self: center;
  border-bottom: solid;
  border-bottom-width: 2px;
  border-color: #f0f0f0;
  flex-grow: 3;
  margin-left: 15px;
  margin-right: 15px;
}

.entityDetail__value {
  font-family: Gilroy-Bold;
  flex-wrap: nowrap;
  font-size: 16px;
}

.entityDetail__pressableValue {
  font-family: Gilroy-Bold;
  flex-wrap: nowrap;
  font-size: 16px;
  color: #005a92;
  cursor: pointer;
}
