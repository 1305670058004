.header {
    display: flex;
    text-align: start;
    flex-direction: row;
    column-gap: 20px;
    justify-content: space-between;
    margin-bottom: 5px;
}

.header__text {
    font-family: Gilroy-Bold;
    font-size: 20px;
    font-weight: bold;
    font-style: normal;
    letter-spacing: 0;
    color: #000000;
    flex-grow: 3;
}

.label__text {
    margin-top: 10px;
    margin-right: 10px;
    font-family: Gilroy-Bold;
    font-size: 16px;
    font-style: normal;
    letter-spacing: 0;
    color: #000000;
}

.marketSelect {
    height: 40px;
    margin-right: 5px;
}

.multiSelectContainer {
    width: 300px;
    height: 30px;
    margin-right: 5px;
}

.scrollableDiv {
    padding: 30px;
    margin-top: 40px;
    margin-bottom: 40px;
    max-height: 70vh;
    max-width: 90%;
    overflow-y: auto;
}