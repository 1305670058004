input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.input__label {
  font-family: Gilroy-Medium;
  font-size: 16px;
  color: #444444;
  display: flex;
  align-items: center;
}

.input__hr {
  flex-grow: 1;
  border-width: 0.5;
  text-align: "center";
  margin-left: 5px;
}

input::placeholder {
  font-size: 14px;
  opacity: 0.5;
}


