.animate {
    animation-duration: 0.75s;
    animation-name: animate-fade;
    animation-timing-function: cubic-bezier(.26,.53,.74,1.48);
    animation-fill-mode: backwards;
}

/* Pop In */
.animate.pop {
    animation-name: animate-pop;
}
@keyframes animate-pop {
    0% {
        opacity: 0;
        transform: scale(0.5, 0.5) translate(-50%, -50%);
    }
    70% {
        opacity: 1;
        transform: scale(1.2, 1.2) translate(-50%, -50%);
    }
    100% {
        opacity: 1;
        transform: scale(1, 1) translate(-50%, -50%);
    }
}