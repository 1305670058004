.inputField {
  border-top: none;
  border-left: none;
  border-right: none;
  width: 150px;
  border-bottom: 0.5px solid black;
  padding-left: 25px;
  height: 20px;
  outline: none;
  width: inherit;
  min-width: 120px;
}
