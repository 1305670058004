.notification-container {
  box-sizing: border-box;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 999999;
  width: 20%;
  padding: 0px 15px;
  max-height: calc(100% - 30px);
  overflow-x: hidden;
  overflow-y: auto;
}

.notification-info {
  background-color: rgb(0, 90, 140);
  opacity: 0.85;
}

.notification-success {
  /* TODO - change with beppo color if needed */
  background-color: #51a351;
  opacity: 0.85;
}

.notification-warning {
  /* TODO - change with beppo color if needed */
  background-color: #f89406;
  opacity: 0.85;
}

.notification-error {
  background-color: rgb(204, 6, 5);
  opacity: 0.85;
}
