.labels {
  font-family: Gilroy-Medium;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  letter-spacing: 0;
  color: #444444;
  display: flex;
  align-items: center;
}

.hr {
  flex-grow: 1;
  border-width: 0.5;
  text-align: "center";
  margin-left: 5px;
}
