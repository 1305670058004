body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
@font-face {
  font-family: "Gilroy-Regular";
  src: local("Gilroy-Regular"),
    url(./fonts/Gilroy-Regular.ttf) format("truetype");
}
@font-face {
  font-family: "Gilroy-Medium";
  src: local("Gilroy-Medium"), url(./fonts/Gilroy-Medium.ttf) format("truetype");
}
@font-face {
  font-family: "Gilroy-Light";
  src: local("Gilroy-Light"), url(./fonts/Gilroy-Light.ttf) format("truetype");
}
@font-face {
  font-family: "Gilroy-Bold";
  src: local("Gilroy-Bold"), url(./fonts/Gilroy-Bold.ttf) format("truetype");
}
@font-face {
  font-family: "NumberplateLLTT-SwitzerlandRd";
  src: local("NumberplateLLTT-SwitzerlandRd"),
    url(./fonts/NumberplateLLTT-SwitzerlandRd.ttf) format("truetype");
}
