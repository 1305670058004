.header__text {
  font-family: Gilroy-Bold;
  font-size: 20px;
  font-weight: bold;
  font-style: normal;
  letter-spacing: 0;
  color: #000000;
}

.showFullCalendar {
  display: inline-block;
  padding: 0;
  border: 0;
  width: max-content;
}
